<template>
  <div id="importProduct">
    <div class="top-search" @click="clickSearch" v-if="!isHistoryShow">
      <div class="search-box">
        <img src="@images/search.png" alt />
        <input type="text" readonly placeholder="搜索商品名称或关键词" />
      </div>
    </div>

    <div class="real-search" v-if="isHistoryShow">
      <div class="search-box">
        <img src="@images/search.png" alt />
        <form style="width:100%;" action onsubmit="return false;">
          <input
            type="search"
            ref="input"
            @keyup="searchEnter"
            v-model="searchVal"
            placeholder="搜索商品名称或关键词"
          />
          <img v-if="searchVal.length" @click="backList" class="clear" src="@images/clear.png" alt />
        </form>
      </div>

      <div class="search-btn" @click="searchBtn(true)">{{searchBtnName}}</div>
    </div>

    <div class="history-box" v-if="isHistoryShow && !isSearchInfoShow">
      <div class="history-top">
        <span>历史搜索</span>
        <img src="@images/delete.png" @click="deleteHistory" alt />
      </div>
      <div class="history-info">
        <div
          class="info-item"
          @click="historyClick(item)"
          v-for="(item, index) in historyInfo"
          :key="index"
        >{{item.product_name}}</div>
      </div>
    </div>
    <div class="history-info-box" v-if="isHistoryShow && isSearchInfoShow" ref="importList">
      <ul class="list">
        <li
          class="item"
          v-for="(item, index) in searchCategoryList"
          :key="index"
          @click="handleSelProduct(item)"
        >
          <img
            class="choice"
            :src="item.choice? require('@images/select.png') : require('@images/un-select.png')"
            alt
          />
          <img v-lazy="item.imgurl" alt />
          <div>
            <p class="title">{{item.product_name}} {{item.specifications}} {{item.unit}}</p>
            <div class="bottom">
<!--              <span class="price">￥{{item.vip_price ? item.vip_price : item.original_price}}</span>-->
              <span>{{item.barcode}}</span>
            </div>
          </div>
        </li>
        <p class="bottom-tips" v-if="Sempty && goodsImportScroller && !SisNothing">别划了，到底儿啦</p>
      </ul>
      <div class="nothing-box" v-if="isHistoryShow && SisNothing">
        <img src="@images/no-goods.png" alt />
        <p>哎呀，没有找到相关商品换个词试试吧</p>
      </div>
    </div>

    <div class="inner-box" v-if="!isHistoryShow">
      <div class="nav-box">
        <div
          :class="firstNav === index ? 'nav-item active' : 'nav-item'"
          @click="firstMenu(item.id, index)"
          v-for="(item, index) in secondCategory"
          :key="index"
        >
          <span>{{item.category_name}}</span>
        </div>
      </div>

      <div class="info-box">
        <div class="second-menu-container" ref="secondMenuContainer">
          <ul class="list">
            <li
              :class="secondNav === index ? 'item active' : 'item'"
              @click="secondMenu(item.id, index, true)"
              v-for="(item, index) in firstCategory"
              :key="index"
            >{{item.category_name}}</li>
          </ul>
        </div>

        <div class="goods-container" ref="goodsList">
          <ul class="list">
            <li
              class="item"
              v-for="(item, index) in categoryList"
              :key="index"
              @click="handleProduct(item)"
            >
              <img v-lazy="item.imgurl" alt />
              <!-- <img class="out" src="@images/sell-out.png" alt v-if="item.product_status == 0" />
              <img class="out" src="@images/edit-fail.png" alt v-if="item.is_examine == 2" />-->

              <div>
                <p class="title">{{item.product_name}} {{item.specifications}}/ {{item.unit}}</p>
                <div class="bottom">
<!--                  <span class="price">￥{{item.vip_price ? item.vip_price : item.original_price}}</span>-->
                  <span class="barcode"> {{item.barcode}}</span>
                  <img
                    :src="item.choice? require('@images/select.png') : require('@images/un-select.png')"
                    alt
                  />
                </div>
              </div>
            </li>
            <p class="bottom-tips" v-if="empty && subMenuScroller">别划了，到底儿啦</p>
          </ul>
        </div>
      </div>
    </div>

    <div class="import-box" @click="isImportShow = !isImportShow">
      <div class="left-box">
        <img src="@images/import.png" alt />
        <div>
          <span>已选{{selectArr.length}}件</span>
          <p>点击查看已选商品</p>
        </div>
      </div>
      <div class="right-box" @click.stop="importSubmitBtn">导入</div>
    </div>

    <van-popup class="position-view" v-model="isImportShow" position="bottom">
      <div class="top-sel">
        <span>已选商品</span>
        <p @click="delAll">全部清空</p>
      </div>
      <div class="sel-box">
        <div class="sel-item" v-for="(item, index) in selectArr" :key="index">
          <div class="left-sel">
            <img v-lazy="item.imgurl" alt />
            <div class="info">
              <p>{{item.product_name}}</p>
              <span>{{item.vip_price ? item.vip_price : item.original_price}}</span>
            </div>
          </div>
          <img src="@images/del.png" alt class="right-img" @click="delSel(item, index)" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import {
  historyList,
  importSearchList,
  historySave,
  historyInfoList,
  initImportList,
  firstImportApi,
  secondImportApi,
  importSubmit,
  productDismount,
  productSellOut,
  productDelete,
} from "../api/requist";
import BScroll from "better-scroll";
export default {
  components: {},
  props: [],
  data() {
    return {
      SisNothing: false,
      Sempty: false,
      SpageCurrent: 1,
      SpageSize: 10,
      isSearchInfoShow: false,
      historyInfo: [],
      searchBtnName: "搜索",
      selectArr: [],
      searchVal: "",
      isNothing: false,
      isHistoryShow: false,
      productInfo: {},
      isImportShow: false,
      firstNav: 0,
      secondNav: 0,
      goodsImportScroller: null,
      firstMenuScroller: null, // 商品列表BS实例
      subMenuScroller: null, // 商品列表BS实例
      goodsListScroller: null, // 商品列表BS实例
      firstCategory: [],
      categoryList: [],
      searchCategoryList: [],
      secondCategory: [],
      pageSize: 15,
      pageCurrent: 2,
      categorySubsetId: "", // 二级菜单id
      empty: false, //是否到底了
    };
  },
  created() {},
  mounted() {
    this.getList();
    this.getHistory();
  },
  watch: {
    searchVal(n, o) {
      if (n === "") {
        this.searchBtnName = "搜索";
      }
    },
  },
  computed: {},

  methods: {
    handleSelProduct(item) {
      this.searchCategoryList.forEach((items) => {
        if (item.id == items.id) {
          items.choice = !items.choice;
          if (items.choice) {
            this.selectArr.push(item);
          } else {
            this.selectArr.splice(this.selectArr.indexOf(item.id), 1);
          }
        }
        this.searchCategoryList = [...this.searchCategoryList];
      });
    },
    async importSubmitBtn() {
      let idStr = [];
      this.selectArr.forEach((item) => {
        idStr.push(item.id);
      });
      let formData = new FormData();
      formData.append("merch_id", localStorage.getItem("merch_id"));
      formData.append("product_id", idStr.join());
      let res = await importSubmit(formData);
      if (res.code === 200) {
        this.$router.push({
          path: "/importResult",
          query: {
            res: res.data,
          },
        });
      } else {
        Toast("导入失败");
      }
    },
    backList() {
      this.SpageCurrent = 1;
      this.searchBtnName = "搜索";
      this.searchVal = "";
      this.isHistoryShow = false;
      this.isSearchInfoShow = false;
      this.subMenuScroller = null;
      this.goodsListScroller = null;
      this.$nextTick(() => {
        this.subMenuScroller = new BScroll(this.$refs.secondMenuContainer, {
          scrollY: false,
          taps: false,
          scrollX: true,
          mouseWheel: true,
          click: true,
          bounceTime: 200,
        });
        this.goodsListScroller = new BScroll(this.$refs.goodsList, {
          scrollY: true,
          scrollX: false,
          mouseWheel: true,
          click: true,
          taps: true,
          pullUpLoad: {
            threshold: 50,
          },
        });
        this.goodsListScroller.on("pullingUp", () => {
          this.secondMenu(this.categorySubsetId);
          this.goodsListScroller.finishPullUp(); // 事情做完，需要调用此方法告诉 better-scroll 数据已加载，否则下拉事件只会执行一次
        });
        this.goodsListScroller.openPullUp();
        this.goodsListScroller.refresh();
        this.subMenuScroller.refresh();
      });
    },
    clickSearch() {
      this.isHistoryShow = true;
    },
    historyClick(item) {
      this.searchVal = item.product_name;
      this.searchBtn(true);
    },
    searchEnter(e) {
      if (e.keyCode == 13) {
        this.searchBtn(true);
      }
    },

    async searchBtn(flag) {
      if (this.searchBtnName === "取消" && flag) {
        return (
          (this.goodsImportScroller = null),
          (this.isHistoryShow = true),
          (this.searchBtnName = "搜索"),
          (this.searchCategoryList = []),
          (this.SpageCurrent = 1),
          (this.searchVal = ""),
          (this.isSearchInfoShow = false)
        );
      }

      if (this.searchVal === "" && this.isImportShow) {
        return Toast("请输入内容");
      }
      this.searchBtnName = "取消";
      // this.isHistoryShow = false;
      // 记录

      if (flag) {
        this.goodsImportScroller = null;
        this.Sempty = false;
        this.searchCategoryList = [];
        this.SpageCurrent = 1;
        this.SpageSize = 10;
      }
      if (this.Sempty) return;
      this.isSearchInfoShow = true;
      if (!this.searchCategoryList.length) {
        let formData = new FormData();
        formData.append("merch_id", localStorage.getItem("merch_id"));
        formData.append("product_name", this.searchVal);
        let res = await historySave(formData);
      }
      let formDataInfo = new FormData();
      formDataInfo.append("merch_id", localStorage.getItem("merch_id"));
      formDataInfo.append("product_name", this.searchVal);
      formDataInfo.append("pageCurrent", this.SpageCurrent);
      formDataInfo.append("pageSize", this.SpageSize);

      let infoRes = await importSearchList(formDataInfo);
      if (infoRes.code === 200) {
        this.searchCategoryList = [
          ...this.searchCategoryList,
          ...infoRes.data.ProductAtlasList,
        ];

        this.selectArr.forEach((itemss) => {
          this.searchCategoryList.forEach((items) => {
            if (itemss.id == items.id) {
              items.choice = true;
            }
          });
        });
        if (infoRes.data.ProductAtlasList.length >= this.SpageSize) {
          this.SpageCurrent = ++this.SpageCurrent;
        } else {
          this.Sempty = true;
        }

        if (!this.goodsImportScroller && !this.Sempty) {
          this.$nextTick(() => {
            this.goodsImportScroller = new BScroll(this.$refs.importList, {
              scrollY: true,
              scrollX: false,
              mouseWheel: true,
              click: true,
              taps: true,
              pullUpLoad: {
                threshold: 50,
              },
            });
            this.goodsImportScroller.on("pullingUp", () => {
              this.searchBtn(false);
              this.goodsImportScroller.finishPullUp(); // 事情做完，需要调用此方法告诉 better-scroll 数据已加载，否则下拉事件只会执行一次
            });
          });
        }

        this.$nextTick(() => {
          if (this.searchCategoryList.length === 0) {
            this.SisNothing = true;
          } else {
            this.SisNothing = false;
          }
        });
      }
    },
    async deleteHistory() {
      let formData = new FormData();
      formData.append("merch_id", localStorage.getItem("merch_id"));
      let res = await historyDelete(formData);
      if (res.code === 200) {
        this.historyInfo = [];
        Toast("删除成功");
      }
    },
    delSel(item, index) {
      this.selectArr.splice(index, 1);
      this.categoryList.forEach((items) => {
        if (item.id == items.id) {
          items.choice = false;
        }
      });
    },

    delAll() {
      this.selectArr = [];
      this.categoryList.forEach((item) => {
        item.choice = false;
      });
    },
    dialog(title, message, confirm, cancel, backFn) {
      Dialog.confirm({
        confirmButtonText: confirm,
        cancelButtonText: cancel,
        confirmButtonColor: "#FF6737",
        cancelButtonColor: "#C0C4CC",
        title: title,
        message: message,
      })
        .then(() => {
          backFn();
        })
        .catch(() => {});
    },

    handleProduct(item) {
      this.categoryList.forEach((items) => {
        if (item.id == items.id) {
          item.choice = !item.choice;
          if (item.choice) {
            this.selectArr.push(item);
          } else {
            this.selectArr.splice(this.selectArr.indexOf(item.id), 1);
          }
        }
        this.categoryList = [...this.categoryList];
      });
    },
    async getList() {
      let formData = new FormData();
      formData.append("merch_id", localStorage.getItem("merch_id"));
      let res = await initImportList(formData);
      if (res.code === 200) {
        this.firstCategory = res.data.SubsetList;
        this.secondCategory = res.data.ProductCategoryList;
        this.categoryList = res.data.ProductList;
        this.categorySubsetId = res.data.SubsetList[0].id;
      }
      this.$nextTick(() => {
        this.subMenuScroller = new BScroll(this.$refs.secondMenuContainer, {
          scrollY: false,
          taps: false,
          scrollX: true,
          mouseWheel: true,
          click: true,
          bounceTime: 200,
        });
        this.goodsListScroller = new BScroll(this.$refs.goodsList, {
          scrollY: true,
          scrollX: false,
          mouseWheel: true,
          click: true,
          taps: true,
          pullUpLoad: {
            threshold: 50,
          },
        });
        this.goodsListScroller.on("pullingUp", () => {
          this.secondMenu(this.categorySubsetId);
          this.goodsListScroller.finishPullUp(); // 事情做完，需要调用此方法告诉 better-scroll 数据已加载，否则下拉事件只会执行一次
        });
      });
    },

    async getHistory() {
      let formData = new FormData();
      formData.append("merch_id", localStorage.getItem("merch_id"));
      let res = await historyList(formData);
      if (res.code === 200) {
        this.historyInfo = res.data;
      }
    },

    async firstMenu(categoryId, index) {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      this.secondNav = 0;
      // this.goodsListScroller = null;
      // this.subMenuScroller = null;
      this.firstCategory = [];
      this.categoryList = [];
      let formData = new FormData();
      formData.append("merch_id", localStorage.getItem("merch_id"));
      formData.append("category_id", categoryId);
      index !== "" ? (this.firstNav = index) : "";
      let res = await firstImportApi(formData);
      if (res.code === 200) {
        this.firstCategory = res.data.SubsetList;

        this.categoryList = res.data.ProductList;
        if (this.selectArr.length) {
          this.selectArr.forEach((itemss) => {
            this.categoryList.forEach((items) => {
              if (itemss.id == items.id) {
                items.choice = true;
              }
            });
          });
        }
        this.subMenuScroller = new BScroll(this.$refs.secondMenuContainer, {
          startX: 0,
          scrollY: false,
          taps: false,
          scrollX: true,
          mouseWheel: true,
          click: true,
          bounceTime: 200,
        });
        this.subMenuScroller.refresh();

        Toast.clear();
      }
    },

    async secondMenu(categroy_subset_id, index, flag) {
      if (this.categorySubsetId != categroy_subset_id || flag) {
        this.empty = false;
        this.pageSize = 10;
        this.pageCurrent = 1;
        this.categoryList = [];
      }

      if (this.empty) return;
      this.goodsListScroller.closePullUp();
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      index !== "" && index !== undefined
        ? (this.secondNav = index)
        : "";

      this.categorySubsetId = categroy_subset_id;
      let formData = new FormData();
      formData.append("merch_id", localStorage.getItem("merch_id"));
      formData.append("category_subset_id", categroy_subset_id);
      formData.append("pageSize", this.pageSize);
      formData.append("pageCurrent", this.pageCurrent);
      let res = await secondImportApi(formData);
      if (res.code === 200) {
        this.goodsListScroller.refresh();
        this.categoryList = [...this.categoryList, ...res.data.ProductList];
        if (this.selectArr.length) {
          this.selectArr.forEach((itemss) => {
            this.categoryList.forEach((items) => {
              if (itemss.id == items.id) {
                items.choice = true;
              }
            });
          });
        }
        if (res.data.ProductList.length) {
          this.pageCurrent = ++this.pageCurrent;
        } else {
          this.empty = true;
        }
        if (this.categoryList.length < this.pageSize) {
          this.empty = true;
        }

        this.goodsListScroller.openPullUp();
        Toast.clear();
      }
    },
  },
};
</script>
<style lang="scss" >
#importProduct {
  min-height: 100vh;
  width: 100%;
  .history-info-box {
    padding-top: 18px;
    height: calc(100vh - 44px - 57px);
    overflow: hidden;
    box-sizing: border-box;
    li {
      padding: 0 12px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      &:last-child {
        margin-bottom: 0;
      }
      img {
        object-fit: cover;
        height: 80px;
        width: 80px;
        margin-right: 8px;
      }

      .choice {
        height: 18px;
        width: 18px;
        margin-right: 12px;
      }

      .out {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
      }

      & > div {
        .title {
          text-align: left;
          height: 40px;
          width: 209px;
          line-height: 20px;
          color: rgba(48, 49, 51, 1);
          font-size: 14px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        .bottom {
          margin-top: 18px;
          display: flex;
          justify-content: space-between;
          .price {
            color: rgba(255, 72, 72, 1);
            font-size: 16px;
          }
          .barcode {
            font-size: 16px;
          }

          .btn {
            width: 60px;
            height: 22px;
            background: rgba(255, 103, 55, 1);
            box-shadow: 0px 2px 4px rgba(255, 103, 55, 0.16);
            border-radius: 13px;
            text-align: center;
            color: rgba(255, 255, 255, 1);
            font-size: 12px;
            line-height: 22px;
          }

          .status {
            font-size: 12px;
            color: #ff6737;
          }
        }
      }
    }

    .bottom-tips {
      font-size: 12px;
      color: rgba(192, 196, 204, 1);
      text-align: center;
      padding-bottom: 24px;
    }
  }
  .van-overlay {
    z-index: 2010 !important;
  }

  .position-view {
    padding-bottom: 57px;
    border-radius: 9px 9px 0px 0px;
    box-sizing: border-box;
    z-index: 2010 !important;
    .top-sel {
      padding: 12px 16px 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 36px;
      span {
        color: rgba(144, 147, 153, 1);
        font-size: 14px;
      }

      p {
        color: rgba(144, 147, 153, 1);
        font-size: 12px;
      }
    }
    .sel-box {
      height: 413px;
      overflow: auto;
      background: rgba(255, 255, 255, 1);
      .sel-item {
        padding: 0px 14px;
        box-sizing: border-box;
        width: 100%;
        height: 96px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .left-sel {
          margin-bottom: 8px;

          display: flex;
          align-items: center;
          img {
            object-fit: cover;
            height: 80px;
            width: 80px;
            margin-right: 8px;
          }

          .info {
            width: 239px;
            p {
              height: 40px;
              font-size: 14px;
              line-height: 20px;
              color: rgba(48, 49, 51, 1);
              margin-bottom: 18px;
              display: -webkit-box;
              overflow: hidden;
              white-space: normal !important;
              text-overflow: ellipsis;
              word-wrap: break-word;
              -webkit-line-clamp: 2;
              text-align: left;
              -webkit-box-orient: vertical;
            }

            span {
              display: block;
              margin: 0;
              text-align: left;
              font-size: 16px;
              color: rgba(255, 72, 72, 1);
              font-weight: bold;
            }
          }
        }

        .right-img {
          margin-bottom: 8px;
          height: 26px;
          width: 26px;
        }
      }
    }
  }

  .real-search {
    height: 44px;
    width: 100%;
    padding: 0 0 0 16px;
    box-sizing: border-box;
    // border-bottom: 1px solid rgba(240, 242, 245, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-btn {
      padding: 0 20px;
      color: rgba(48, 49, 51, 1);
      font-size: 16px;
    }
    .search-box {
      flex: 1;
      height: 32px;
      background: rgba(245, 247, 250, 1);
      border: 1px solid #cfd2d8;
      opacity: 1;
      border-radius: 15px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      box-sizing: border-box;
      position: relative;
      img {
        height: 14px;
        width: 14px;
        margin-right: 8px;
      }
      .clear {
        position: absolute;
        right: 0;
        top: 8px;
        height: 16px;
        width: 16px;
      }

      input {
        color: #303133;
        width: 100%;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        border: none;
        outline: none;
        box-sizing: border-box;
        background: rgba(245, 247, 250, 1);
        &::placeholder {
          font-size: 14px;
          color: rgba(192, 196, 204, 1);
        }
      }
    }
  }

  .history-box {
    min-height: calc(100vh - 44px);
    padding: 12px 16px;
    box-sizing: border-box;
    .history-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 14px;
        font-weight: bold;
        color: rgba(48, 49, 51, 1);
      }

      img {
        height: 20px;
        width: 20px;
      }
    }

    .history-info {
      margin-top: 8px;
      width: 100%;
      .info-item {
        float: left;
        margin-right: 8px;
        margin-bottom: 8px;
        padding: 0px 12px;
        height: 28px;
        line-height: 28px;
        box-sizing: border-box;
        background: rgba(240, 242, 245, 1);
        opacity: 1;
        color: rgba(96, 98, 102, 1);
        border-radius: 14px;
        font-size: 14px;
      }
    }
  }

  .nothing-box {
    height: calc(100vh - 44px);
    padding-top: 64px;
    box-sizing: border-box;
    img {
      height: 248px;
      width: 100%;
      display: block;
      margin: 0 auto 12px;
    }
    p {
      margin: 0 auto;
      width: 132px;
      line-height: 18px;
      color: rgba(144, 147, 153, 1);
      font-size: 12px;
    }
  }

  .top-search {
    height: 44px;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(240, 242, 245, 1);
    display: flex;
    align-items: center;
    .search-box {
      width: 100%;
      height: 32px;
      background: rgba(245, 247, 250, 1);
      border: 1px solid rgba(192, 196, 204, 1);
      opacity: 1;
      border-radius: 15px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      box-sizing: border-box;
      img {
        height: 14px;
        width: 14px;
        margin-right: 8px;
      }

      input {
        color: #303133;
        width: 100%;
        height: 100%;
        font-size: 14px;
        border: none;
        outline: none;
        box-sizing: border-box;
        background: rgba(245, 247, 250, 1);
        &::placeholder {
          font-size: 14px;
          color: rgba(192, 196, 204, 1);
        }
      }
    }
  }

  .import-box {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2020;
    width: 100%;
    height: 57px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px -3px 4px rgba(119, 118, 118, 0.08);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    box-sizing: border-box;
    .left-box {
      display: flex;
      img {
        height: 42px;
        width: 42px;
        margin-right: 6px;
      }
      span {
        font-size: 14px;
        text-align: left;
        display: block;
        margin-bottom: 2px;
        color: rgba(255, 103, 55, 1);
      }
      p {
        text-align: left;
        color: rgba(144, 147, 153, 1);
        font-size: 12px;
      }
    }

    .right-box {
      width: 88px;
      height: 36px;
      border: 1px solid rgba(255, 103, 55, 1);
      opacity: 1;
      border-radius: 20px;
      font-size: 14px;
      text-align: center;
      font-weight: bold;
      line-height: 36px;
      color: rgba(255, 103, 55, 1);
    }
  }

  .nav-box {
    width: 94px;
    padding-bottom: 57px;
    box-sizing: border-box;
    height: calc(100vh - 44px);
    border-right: 1px solid rgba(240, 242, 245, 1);
    .nav-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      line-height: 44px;
      text-align: center;
      color: rgba(48, 49, 51, 1);
      font-size: 14px;
      &.active {
        span {
          display: block;
          width: 78px;
          height: 28px;
          line-height: 28px;
          background: rgba(255, 103, 55, 1);
          border-radius: 14px;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }

  .inner-box {
    display: flex;
  }

  .info-box {
    padding: 12px 0 57px 6px;
    flex: 1;
    height: calc(100vh - 44px);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 0;
    .second-menu-container {
      overflow: hidden;
      height: 24px;
      white-space: nowrap;
      margin-bottom: 24px;
      .list {
        float: left;
        white-space: nowrap;
        height: 24px;
        display: inline-block;
      }
      .item {
        display: inline-block;
        margin: 0 6px;
        height: 24px;
        padding: 0 8px;
        background: rgba(245, 247, 250, 1);
        border: 1px solid #cfd2d8;
        border-radius: 2px;
        font-size: 14px;
        color: rgba(48, 49, 51, 1);
        line-height: 24px;
        box-sizing: border-box;
        &:last-child {
          margin-right: 12px;
        }
        &.active {
          color: #ff6737;
          background: rgba(255, 103, 55, 0.2);
          border: 1px solid rgba(255, 103, 55, 1);
        }
      }
    }

    .goods-container {
      height: calc(100% - 60px);
      overflow: hidden;
      .bottom-tips {
        font-size: 12px;
        color: rgba(192, 196, 204, 1);
      }

      li {
        position: relative;
        display: flex;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
        img {
          object-fit: cover;
          height: 80px;
          width: 80px;
          margin-right: 8px;
        }

        .out {
          position: absolute;
          top: 0;
          left: 0;
          margin: 0;
        }

        & > div {
          .title {
            text-align: left;
            height: 40px;
            width: calc(100vw - 95px - 6px - 80px - 8px - 16px);
            line-height: 20px;
            color: rgba(48, 49, 51, 1);
            font-size: 14px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          .bottom {
            margin-top: 18px;
            display: flex;
            justify-content: space-between;
            .price {
              color: rgba(255, 72, 72, 1);
              font-size: 16px;
            }

            img {
              height: 18px;
              width: 18px;
            }

            .btn {
              width: 60px;
              height: 22px;
              background: rgba(255, 103, 55, 1);
              box-shadow: 0px 2px 4px rgba(255, 103, 55, 0.16);
              border-radius: 13px;
              text-align: center;
              color: rgba(255, 255, 255, 1);
              font-size: 12px;
              line-height: 22px;
            }

            .status {
              font-size: 12px;
              color: #ff6737;
            }
          }
        }
      }
    }
  }

  .handle-item {
    height: 46px;
    line-height: 46px;
    color: rgba(48, 49, 51, 1);
    font-size: 14px;
    background: rgba(255, 255, 255, 1);
    &:nth-child(1) {
      border-bottom: 1px solid rgba(242, 246, 252, 1);
    }

    &:nth-child(2) {
      border-bottom: 1px solid rgba(242, 246, 252, 1);
    }
    &.active {
      color: #ff4848;
    }
    &:nth-child(3) {
      margin-bottom: 12px;
    }
  }
}
</style>
